import React from "react";
import saran from "../../../assets/image/parthner/saran.png";
import alfa from "../../../assets/image/parthner/alfa.png";

const Testimonials = () => {
  // Demo Data
  const testimonials = [
    {
      name: "Ч.Батзаяа",
      position: "Альфа Медикал Сэргээн засах төв эмнэлгийн Захирал",
      feedback: `Альфа Медикал Сэргээн засах төв нь 2024 оноос эхлэн Дүрс оношилгооны цахим төвийн
чадварлаг баг хамт олонтой хамтран ажиллаж байна.<br/>
Тус төв нь үйлчлүүлэгчийн шинжилгээний хариуг хурдан шуурхай, чанартай, цаг алдалгүй
ирүүлдэгт талархдаг.<br/>
Бидний цаашдын хамтын ажиллагаа өргөжин тэлнэ гэдэгт итгэлтэй байна.`,
      imageUrl: alfa,
    },
    {
      name: "Б. Сарантуяа",
      position: "Дархан уул аймгийн ‘Саран–Элит’ эмнэлгийн Захирал",
      feedback: `Дархан уул аймгийн “Саран-Элит” эмнэлэг нь 2019 онд Phillips Brilliance 64 зүслэгт
компьютерт томографийн аппаратыг суурилуулснаас хойш Дүрс оношилгооны цахим төвийн хамт
олонтой хамтран ажилаж байна.<br><br>
Бидний зорилго Дархан хотдоо хийгдэж байгаагүй оношилгоо, тусламж үйлчилгээг ард
иргэддээ үзүүлж, орчин цагтай хөл нийлүүлэн алхах байв. Зорилгодоо хүрэхээр компьютерт
томографийн аппаратаа суурилуулан ажиллаж эхлэхэд хариу унших эмч тухайн үед манайд
байгаагүй. Энэ цаг мөчид анхны зайн оношилгооны төв Монголд үйл ажиллагаагаа явуулж эхэлсэн
байсан тул бид бөөн баяр болон холбогдсоноор бидний хамтын ажиллагаа маань эхлэж байсныг
дурсан санахад сайхан байна.<br><br>
Дүрс оношилгооны цахим төвийн эмч нар нь  компьютерт томографийн дүгнэлт бичих олон
жилийн туршлагатайгаас гадна, толгой-нуруу, цээж, хэвлий, яс үе мөч гэсэн чиглэлийн дагуу
дагнан, төрөлжиж хариугаа бичдэг. Хөдөө орон нутагт дүрс оношилгооны нарийн мэргэжлийн эмч
дутагдалтай, түүнчлэн тодосгогчтой шинжилгээнд зөв, чанартай дүгнэлт бичих нь үйлчлүүлэгчийн
амь насыг аврах, цаашдын эмчилгээний тактикийг сонгоход маш чухал байдаг тул бид Дүрс
оношилгооны цахим төвийн туршлагатай хамт олны бичсэн хариуг үйчлүүлэгч нартаа өгөхдөө
итгэл дүүрэн байдаг билээ.<br><br>
Та бүхэндээ баярлалаа. Цаашдын ажил үйлсэд нь өндөр амжилт хүсье!`,
      imageUrl: saran,
    },
  ];

  return (
    <section className="py-10 bg-gray-200">
      <div className="container mx-auto grid grid-cols-12 gap-6 px-6 ">
        {/* Section Title */}
        <div className="col-span-12">
          <div className="text-center uppercase text-primary text-sm">
            Сэтгэгдэл
          </div>
          <div className="text-center capitalize font-semibold text-2xl">
            Харилцагчдын талархал
          </div>
        </div>
        {/* Testimonials Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 col-span-12">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="border border-transparent hover:border-stone-100 cursor-pointer p-6 rounded-xl group transition-all"
            >
              <p
                className="text-gray-700 text-sm mb-4"
                dangerouslySetInnerHTML={{ __html: testimonial.feedback }}
              ></p>
              <div className="flex items-center gap-4 mt-4">
                <img
                  className="w-12 h-12 rounded-full object-cover"
                  src={testimonial.imageUrl}
                  alt={testimonial.name}
                />
                <div>
                  <div className="font-semibold text-gray-900">
                    {testimonial.name}
                  </div>
                  <div className="text-sm text-gray-500">
                    {testimonial.position}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
