import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { instance } from "utils/axios"; // Assuming this is the axios instance you are using
import ShadowDOM from "react-shadow";

const ServiceDetail = () => {
  const { slug } = useParams(); // Extract `id` from route params
  const [data, setData] = useState({ title: "", content: "" });

  useEffect(() => {
    // Fetch data from the server
    instance
      .get("/service/" + slug)
      .then((response) => {
   
        setData(response.data); // Assuming API response returns an array of services
      })
      .catch((error) => console.error("Error fetching services:", error));
  }, [slug]);

  return (
    <div>
      {" "}
      <section className="py-10 bg-primary">
        <div className="container mx-auto px-6">
          <div className="font-bold text-xl text-white">{data?.name}</div>
        </div>
      </section>
      <div className="container mx-auto p-2  text-justify">
        <ShadowDOM.div>
          {" "}
          {/* ShadowRoot-ийн оронд ShadowDOM ашиглана */}
          <div>
            <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
          </div>
        </ShadowDOM.div>
      </div>
    </div>
  );
};

export default ServiceDetail;
