import React, { useState } from "react";
import { Modal } from "antd";
import { CiMail, CiPhone } from "react-icons/ci";
import { instance } from "utils/axios"; // Axios utils import
import img1 from "../../assets/image/med_logo.jpg";
import img2 from "../../assets/image/bri_logo.jpg";
const ContactUs = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.target);
    const data = {
      lastName: formData.get("lastName"),
      firstName: formData.get("firstName"),
      phone: formData.get("phone"),
      email: formData.get("email"),
      description: formData.get("description"),
    };
  

    instance
      .post("/contact", data) // Backend API endpoint
      .then((response) => {
        Modal.success({
          title: "Амжилттай!",
          content: "Таны мэдээлэл амжилттай хадгалагдлаа.",
        });
        event.target.reset(); // Reset form
      })
      .catch((error) => {
        Modal.error({
          title: "Алдаа!",
          content: "Өгөгдлийг хадгалах үед алдаа гарлаа. Дахин оролдоно уу.",
        });
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <section className="py-10 bg-primary">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-12 gap-6">
            {/* First Column */}
            <div className="col-span-12 md:col-span-6">
              <div className="font-bold text-xl text-white">
                Таньд асуулт байна уу? <br />
                Бидэнтэй холбогдоод мэрэгжилтэнгүүдээс асуугаарай.
              </div>
            </div>

            {/* Second Column */}
            <div className="col-span-12 md:col-span-6">
              <div className="font-bold text-xl text-white">
                КТГ болон СРТ шинжилгээний цаг авах, холбоо барих
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-10">
        <div className="container mx-auto px-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-6">
            <form className="grid grid-cols-12 gap-3" onSubmit={handleSubmit}>
              <div className="mb-5 col-span-6">
                <label
                  htmlFor="lastName"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Овог
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Овог"
                  required
                />
              </div>
              <div className="mb-5 col-span-6">
                <label
                  htmlFor="firstName"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Нэр
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Нэр"
                  required
                />
              </div>
              <div className="mb-5 col-span-6">
                <label
                  htmlFor="phone"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Утас
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Утас"
                  required
                  pattern="[0-9]{8}"
                />
              </div>
              <div className="mb-5 col-span-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Имэйл
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Имэйл"
                  required
                />
              </div>
              <div className="mb-5 col-span-12">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Дэлгэрэнгүй тайлбар
                </label>
                <textarea
                  rows={4}
                  id="description"
                  name="description"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <button
                type="submit"
                className="text-white col-span-12 bg-primary hover:bg-primary/90 focus:ring-4 focus:outline-none focus:ring-primary/50 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                disabled={loading}
              >
                {loading ? "Илгээж байна..." : "Илгээх"}
              </button>
            </form>
          </div>
          <div className="col-span-12 md:col-span-6 flex flex-col gap-3">
            <div className="bg-white shadow rounded-xl flex items-center p-6 gap-3">
              <div className="rounded-full p-2 border border-primary">
                {/* <CiPhone className="text-3xl text-primary" /> */}
                <img
                  fill="true"
                  className="rounded-full w-[100px] h-[100px] object-cover "
                  src={img1}
                  alt="Reading Center"
                />
              </div>
              <div className="flex-1">
                <div className="font-bold text-l mb-3">Мед Травма эмнэлэг</div>
                <div>
                  {" "}
                  Хаяг-Баянгол дүүрэг, Төв гэмтлийн эмнэлгийн хойд талд,
                  Медтравма эмнэлэг<></> Утас-95162407
                </div>
              </div>
            </div>
            <div className="bg-white shadow rounded-xl flex items-center p-6 gap-3">
              <div className="rounded-full p-2 border border-primary">
                {/* <CiPhone className="text-3xl text-primary" /> */}
                <img
                  fill="true"
                  className="rounded-full w-[100px] h-[100px] object-cover "
                  src={img2}
                  alt="Reading Center"
                />
              </div>
              <div className="flex-1">
                <div className="font-bold text-xl md:text-l mb-3">
                  Бриллиант эмнэлэг
                </div>
                <div>
                  Хаяг- Баянзүрх дүүрэг, 18-р хороо, Нам Ян Жү-гийн гудамж 18
                  <br /> Утас- 76096666
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
      

export default ContactUs;
