import React, { useEffect, useRef } from "react";

const VideoUs = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    // Ensure the iframe is ready before sending commands
    const handleIframeReady = () => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Play the YouTube video when it enters the viewport
              iframe.contentWindow.postMessage(
                '{"event":"command","func":"playVideo","args":""}',
                "*"
              );
            } else {
              // Pause the YouTube video when it exits the viewport
              iframe.contentWindow.postMessage(
                '{"event":"command","func":"pauseVideo","args":""}',
                "*"
              );
            }
          });
        },
        { threshold: 0.5 } // Trigger when 50% of the video is visible
      );

      if (iframe) {
        observer.observe(iframe);
      }

      return () => {
        if (iframe) {
          observer.unobserve(iframe);
        }
      };
    };

    // Wait for iframe to load before observing
    iframe.addEventListener("load", handleIframeReady);

    return () => {
      iframe.removeEventListener("load", handleIframeReady);
    };
  }, []);

  return (
    <section className="py-10 bg-gray-200  text-justify">
      <div className="container mx-auto grid grid-cols-12 gap-6 px-6">
        {/* Left Column */}
        <div className="col-span-12 md:col-span-6">
          <div className="text-center uppercase text-primary text-sm">
            Товч Видео
          </div>
          {/* <div className="text-center capitalize font-semibold text-2xl mb-10">
            Бидний тухай
          </div> */}
          <div className="mb-3 leading-relaxed">
            Дүрс Оношилгооны ЦАХИМ ТӨВ нь орон зайнаас үл хамааран дүрс
            оношилгооны зургийн хариуг хурдан шуурхай уншиж олгосноор хүн амд
            үзүүлж байгаа эрүүл мэндийн тусламж үйлчилгээний чанарыг
            сайжруулахад өөрсдийн гэсэн хувь нэмрээ оруулан ажиллаж байна.
            <br />
            <br /> Манай төв мэдрэл, толгой хүзүү, цээжний хөндий, хэвлийн
            хөндий, бага аарцаг, яс үе мөчний дүрс оношилгооны чиглэлээр дагнан
            нарийссан эмч нарын баг бүрэлдэхүүнтэй бөгөөд энэ нь мэргэжлийн
            тусламж үйлчилгээг өндөр чанартай үзүүлж, оношийн алдааг эрс
            багасгадаг давуу талтай.
          </div>
        </div>

        {/* Right Column */}
        <div className="col-span-12 md:col-span-6">
          <div className="relative pb-[56.25%] h-0">
            <iframe
              ref={iframeRef}
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.youtube.com/embed/xABpParh45Y?enablejsapi=1&autoplay=0&loop=1&playlist=xABpParh45Y&modestbranding=1&controls=0&showinfo=0&rel=0"
              title="DCDR Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoUs;
