import React, { useState } from "react";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

 const faqData = [
   {
     question: "Телерадиологи гэж юу вэ?",
     answer:
       "Телерадиологи гэдэг нь алсын зайнаас онлайнаар дүрс оношилогооны дүгнэлт бичих цахим үйлчилгээ юм.",
   },
   {
     question: "Телерадиологийн давуу тал юу вэ?",
     answer: `
       - Цаг хугацааг хэмнэнэ.
       <br/>- Орон зайг үл хамаарна.
       <br/>-  Нарийн мэргэшсэн эмч нарын баг өндөр чанартай мэргэжлийн тусламж үйлчилгээ үзүүлнэ. `,
   },
   {
     question: "Шинжлүүлэгчийн зурагт хэрхэн дүгнэлт өгдөг вэ?",
     answer:
       "Шинжлүүлэгчийн зураг PACS /Picture Archiing and Communication System/ -   аюулгүй дүрс оношилгооны платформоор дамжин ирсэн зургийг тухайн чиглэлээр мэргэшсэн 1-2 эмч давхар уншилтаар хариу бичигддэг. Ховор, хүнд тохиолдолд эмч нарын зөвөлгөөний шийдвэрийг дүгнэлтэнд тусгаж өгдөг.",
   },
   {
     question: "Ямар, ямар чиглэлээр хариу бичдэг вэ?",
     answer: `
       - Толгой хүзүү, мэдрэл
       <br/>- Хэвлийн хөндий
       <br/>-  Цээжний хөндий
        <br/>- Яс- үе мөч
          <br/>- Эрэгтэй болон эмэгтэй бага аарцаг
            <br/>- Хөхний дүрс оношилгооны хүрээнд КТГ, СРТ, рентген шинжилгээний дүгнэлт бичнэ.
        
        `,
   },
   {
     question: "Зургийн чанар ямар байдаг вэ?",
     answer:
       "PACS-аар дамжин шилжиж ирсэн зураг нь нягтрал сайтай байдаг тул дүрс оношилгооны дүгнэлт бичихэд асуудал гардаггүй.",
   },
   {
     question:
       "Танай төв эмнэлгийн үйл ажиллагаа явуулах албан ёсны зөвшөөрөлтэй юу?",
     answer:
       "DCDR төв нь Дүрс оношилгооны чиглэлээр үйл ажиллагаа явуулах тусгай зөвшөөрлийн лицензтэй. ",
   },
   {
     question:
       "Хувь хүмүүсийн хүсэлтийн дагуу дүрс оношилгооны зурагт дүгнэлт өгдөг үү?",
     answer:
       "Хувь хүн болон байгууллагын харилцан зөвшилцөлийн  үндсэн дээр дүрс оношилгооны шинжилгээний дүгнэлт бичих бүрэн боломжтой. ",
   },
  
 ];


  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="py-10  text-justify">
      <div className="container mx-auto px-6">
        <div className="text-center mb-10">
          <h4 className="text-yellow-500 font-bold text-sm uppercase mb-2">
            Q/A
          </h4>
          <h2 className="text-2xl font-bold">Түгээмэл Асуулт, Хариулт</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className={`border rounded-lg p-4  border-primary ${
                activeIndex === index ? "bg-blue-50" : "bg-white"
              }`}
            >
              <div
                onClick={() => toggleFAQ(index)}
                className="flex justify-between items-center cursor-pointer"
              >
                <h3 className="font-semibold text-gray-900">
                  Q: {faq.question}
                </h3>
                <button
                  className={`transform transition-transform duration-200 ${
                    activeIndex === index ? "rotate-180" : ""
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-5 h-5 text-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
              </div>
              {activeIndex === index && (
                <div
                  className="mt-3 text-sm text-gray-700"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                ></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
