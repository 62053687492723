import { ReactComponent as MapSVG } from '../../../assets/image/mn.svg'

const Map = () => {
  return (
    <div className="container mx-auto px-6 pb-10">
      <div className="grid grid-cols-3">
        <div className="col-span-3 xl:col-span-2">
          <MapSVG className="w-full">asdf</MapSVG>
        </div>
        <div className="col-span-3 xl:col-span-1">
          <div className="uppercase text-primary text-sm text-center xl:text-left">
            Цар хүрээ
          </div>
          <div className="capitalize font-semibold text-2xl mb-10 text-center xl:text-left">
            Монгол улс
          </div>
          <div className="text-justify">
            Манай төв нь “Аксис системс” ХХК компаний тусгай зориулалтын
            мэргэжлийн их өгөгдөл дамжуулах өндөр хурдны системийг ашиглан, 2024
            оноос төвлөрсөн PACS системд нэгдэж, улсын хэмжээнд бүх шатлалын
            эмнэлгүүдээс зураг хүлээн авдаг. Хамтран ажиллах эмнэлгүүд нь local
            PACS-ийг суурилуулснаар зургийг дүрслэлийн өндөр чанартайгаар манай
            төв рүү хурдан дамжуулах нөхцөл бүрдэж байна.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Map