import { IoCheckmarkDone, IoDiamondOutline } from "react-icons/io5";
import { GrCertificate } from "react-icons/gr";
import { LuGoal } from "react-icons/lu";
import { IoEyeOutline } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
const AboutUs = () => {
  return (
    <section className="py-10">
      <div className="container mx-auto grid grid-cols-12 gap-6 px-6">
        <div className="col-span-12 md:col-span-6">
          <div className="text-center uppercase text-primary text-sm">
            Товч танилцууллага
          </div>
          <div className="text-center capitalize font-semibold text-2xl mb-10">
            Бидний тухай
          </div>
          <div className="mb-3 text-justify">
            Дүрс оношилгооны цахим төв нь анх 2018 онд “Reading Center”
            нэртэйгээр байгуулагдсан. <br />
            <br />
            Бид үйлчлүүлэгчид ээлтэй, чанартай тусламж үйлчилгээг үзүүлэхийн
            тулд дүрс оношилгооны дүгнэлтийг алсын зайнаас хурдан шуурхай,
            найдвартай хүргэх шинэчлэлийг түүчээлэн хийж, хөгжлийн замаар
            тасралтгүй урагшлахыг зорьж байна.
            <br />
            <br />
            Манай төв нь өмнөх ололтоосоо ямагт урагш тэмүүлж, шинэчлэл, хурд,
            эв нэгдэл гэсэн үгсийг зарчмаа болгож ажилладаг.{" "}
          </div>
          {/* <div className="flex flex-col gap-3">
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary" />
              Эмнэлэг
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary" />
              Diagnostic Centers
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary" />
              Individual Radiologists
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary" />
              Governments
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary" />
              WHO, health organizations
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary" />
              NGOs and Public Private Partnership firms
            </div>
          </div> */}
        </div>
        <div className="col-span-12 md:col-span-6 flex flex-col gap-6">
          <div className="bg-white shadow rounded flex items-center p-6 gap-3">
            <div className="rounded-full p-6 border border-primary">
              <LuGoal className="text-[20px] md:text-[40px] text-primary" />
            </div>
            <div className="flex-1">
              <div className="font-bold text-xl md:text-2xl mb-3">
                ЭРХЭМ ЗОРИЛГО
              </div>
              <div className=" text-justify">
                Олон улсын хэмжээнд хүлээн зөвшөөрөгдсөн дүрс оношилгооны алсын
                зайн үйлчилгээ үзүүлдэг ЦАХИМ ТӨВ болох
              </div>
            </div>
          </div>
          <div className="bg-white shadow rounded flex items-center p-6 gap-3">
            <div className="rounded-full p-6 border border-primary">
              <IoEyeOutline className="text-[20px] md:text-[40px] text-primary" />
            </div>
            <div className="flex-1">
              <div className="font-bold text-xl md:text-2xl mb-3">
                АЛСЫН ХАРАА
              </div>
              <div className=" text-justify">
                Монгол улсын хэмжээнд дүрс оношилгооны алсын зайн үйлчилгээний
                чанар хүртээмжийг дээшлүүлэх болон боловсон хүчнийг
                чадавхижуулах замаар салбарын хөгжилд өөрсдийн хувь нэмэр
                оруулах
              </div>
            </div>
          </div>
          <div className="bg-white shadow rounded flex items-center p-6 gap-3">
            <div className="rounded-full p-6 border border-primary">
              <IoDiamondOutline className="text-[20px] md:text-[40px] text-primary" />
            </div>
            <div className="flex-1">
              <div className="font-bold text-xl md:text-2xl mb-3">
                ҮНЭТ ЗҮЙЛ
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex gap-3">
                  <GoDotFill className=" text-primary" />
                  Найдвартай байдал
                </div>
                <div className="flex gap-3">
                  <GoDotFill className=" text-primary" />
                  Хөгжил дэвшил
                </div>
                <div className="flex gap-3">
                  <GoDotFill className=" text-primary" />
                  Хурд
                </div>
              </div>
            </div>
          </div>
          {/* <div className="bg-white shadow rounded flex items-center p-6 gap-3">
            <div className="rounded-full p-6 border border-primary">
              <GrCertificate className="text-[20px] md:text-[40px] text-primary" />
            </div>
            <div className="flex-1">
              <div className="font-bold text-xl md:text-2xl mb-3">
                ХАМТЫН АЖИЛЛАГАА
              </div>
              <div>
                Гадаад дотоодын мэргэжлийн ба бусад мэргэжлийн нийгэмлэгүүдтэй
                хамтран ажиллах, туршлага солилцох
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default AboutUs