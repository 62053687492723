import React from "react";
import { IoIosMedical } from "react-icons/io"; // Example icon, replace with actual icons for each service
import {
  MdOutlineHandshake,
  MdAccessTime,
} from "react-icons/md"; // More example icons
import { BsGraphUpArrow } from "react-icons/bs";

import { RiPinDistanceLine } from "react-icons/ri";

const Operation = () => {
  const services = [
    {
      icon: <IoIosMedical />,
      title: "ЭРҮҮЛ МЭНДИЙН САЛБАРЫН ХҮРЭЭНД",
      description: `Монгол улсын - Засгийн газрын Алсын хараа 2050 урт хугацааны хөгжлийн бодлогын хүрээнд “Цахим үндэстэн” болох зорилтот хөтөлбөрийг дэмжиж ажиллах`,
    },
    {
      icon: <RiPinDistanceLine />,
      title: "АЛСЫН ЗАЙН ҮЙЛЧИЛГЭЭ",
      description: `Дүрс оношилгооны нарийн мэргэжлийн оношилгоог орон зайг үл хамааран, цаг хугацааг товчлон үйлүүлэгчдэд хурдан шуурхай, хүртээмжтэй үзүүлэх. Алслагдсан бүс нутгийн дүрс оношилгооны чиглэлийн боловсон хүчнийг чадавхижуулахад хувь нэмрээ оруулах`,
    },
    {
      icon: <BsGraphUpArrow />,
      title: "ТАСРАЛТГҮЙ ХӨГЖИЛ",
      description: `Дүрс оношилгооны чиглэлийн урт ба богино хугацааны мэргэшүүлэх
сургалт болон хурал семинарыг зохион байгуулах, дэлхийн чиг
хандлагтай мөр зэрэгцэн хөгжих`,
    },
    {
      icon: <MdOutlineHandshake />,
      title: "ХАМТЫН АЖИЛЛАГАА",
      description: `Гадаад дотоодын мэргэжлийн ба бусад мэргэжлийн нийгэмлэгүүдтэй
хамтран ажиллах, туршлага солилцох`,
    },
  ];

  return (
    <div className="pt-12 pb-20 bg-gray-200  text-justify">
      <div className="container mx-auto">
        <div className="text-center mb-10">
          {/* <div className="text-lg uppercase text-primary mb-2">Чиглэл</div> */}
          <h2 className="text-3xl capitalize   font-semibold">
            ҮЙЛ АЖИЛЛАГААНЫ ҮНДСЭН ЧИГЛЭЛ
          </h2>
        </div>
        <div className="flex flex-wrap justify-center">
          {services.map((service, index) => (
            <div key={index} className="w-full md:w-1/4 px-4 mb-10">
              <div className="flex">
                <div className="text-5xl text-primary">{service.icon}</div>
                <div className="ml-4">
                  <h5 className="text-xl font-bold text-primary mb-2">
                    {service.title}
                  </h5>
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Operation;
